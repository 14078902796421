import {advFn} from './adv';
import {cmpDialog} from '../cmp-dialog';
import 'select2';
import device from 'current-device';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { error } from 'jquery';

/*export const fn = {
};*/

export const coralCommentsEdition = ['ra_us', 'fr', 'us', 'ev_us', 'ev_br'];
export const initCoralByCommentsBtnEditions = ['ra_us', 'fr', 'us', 'ev_us', 'ev_br'];

export const executeAsync = (func, time) => {
  setTimeout(func, time ? time : 0);
}

export function pageMessages(messageStore, $boxSelector, itemStylePrefix, buttonFun) {
  if (messageStore && messageStore.length) {
    var cookie = true,
      mes,
      $box = $($boxSelector).first();
    if ($box.length) {
      for (var i = 0, c = messageStore.length; i < c; i++) {
        mes = messageStore[i];
        if (!mes.name) {
          continue;
        }
        cookie = $.cookie(mes.name);
        if (cookie) {
          continue;
        } else {
          var $message = $(`<div class="${itemStylePrefix}">
              <div class="${itemStylePrefix}-wrapper">
              <span class="${itemStylePrefix}-text">${mes.message}</span>
              <div class="${itemStylePrefix}-button-box">
                <span 
                  class="${itemStylePrefix}-button" 
                  data-button
                  data-id="${buttonFun}" 
                  id="${mes.name}_button" 
                  data-cookie-name="${mes.name}">
                ${i18n.button_accept || 'Accept'}
                </span>
                <span
                  class="${itemStylePrefix}-button-close" 
                  data-button
                  data-id="${buttonFun}" 
                  id="${mes.name}_button_close" 
                  data-close="1"
                  data-cookie-name="${mes.name}">
                </span>
              </div>
              </div>
            </div>`);
          $box.html($message);
          setTimeout(_ => {
            $message.addClass('active');
          }, 100);
          break;
        }
      }
    }
  }
}

export function showHideBtn(filterContainer) {
  const filterBtn = filterContainer.querySelector('.filter-btn'),
    textBtn = filterContainer.querySelector('.btn-show-more-less'),
    filterList = filterContainer.querySelector('.filter-list'),
    filterListColumns = filterContainer.querySelector('.filter-list.columns');

  if (filterBtn == 'undefined' || filterBtn == null) return;

  let offsetList = e => {
    let isActive = filterBtn.classList.contains('active');

    if (
      filterList.querySelectorAll('a').length == 0 ||
      filterList.clientHeight == 0
    )
      return;

    if($(filterList).length != 0 && filterList.querySelectorAll('a:not(.current)')[0]){
      let linkHeight = filterList.querySelectorAll('a:not(.current)')[0]
          .offsetHeight,
        listHeight = filterList.offsetHeight;

      if (
        (filterList.offsetHeight < filterList.scrollHeight ||
          filterList.offsetWidth < filterList.scrollWidth) &&
        !isActive || filterList.querySelectorAll('a').length > 5 && filterListColumns !== null
      ) {
        filterBtn.style.display = 'block';
      } else if (isActive && listHeight == linkHeight) {
        filterBtn.click();
      } else if (!isActive) {
        filterBtn.style.display = 'none';
        if (textBtn != null) textBtn.innerHTML = translate('more', 'more');
      }
    }
  };
  offsetList();
  window.addEventListener('load', e => {
    let dataCollapsed = filterBtn.getAttribute('data-collapsed');

    if (
      (filterList.offsetHeight < filterList.scrollHeight ||
        filterList.offsetWidth < filterList.scrollWidth) &&
      dataCollapsed
    ) {
      filterBtn.click();
    }
  });
  window.addEventListener('resize', offsetList);

  if (textBtn == null) return; // if we does not have a btn translate

  filterBtn.addEventListener('click', e => {
    let isActive;

    setTimeout(() => {
      // this stuff for get active class btn
      isActive = filterBtn.classList.contains('active');
      if (isActive) {
        textBtn.innerHTML = translate('less', 'less');
      } else {
        textBtn.innerHTML = translate('more', 'more');
      }
    }, 100);
  });
}

export function disableScroll(method) {
  let defaultOptions = {
    selector: 'body',
  };

  let methods = {
    init: function (options) {
      let settings = $.extend(defaultOptions, options),
        scrollBarWidth =
          window.innerWidth - document.documentElement.clientWidth,
        $selector = document.querySelector(settings.selector),
        $header = $('.headerStick .m1-header-main'),
        position = $header.css('position'),
        $addSection = $('.section-sidebar').css('right');

      disableBodyScroll($selector, {
        allowTouchMove: (el) => {
          if (el.closest('#onetrust-consent-sdk')) return true;
        }
      });
      $('html').addClass('_side-hidden');

      if (scrollBarWidth === 0) return;
      $('body .root').css({
        'margin-right': scrollBarWidth,
      });
    },

    destroy: function () {
      clearAllBodyScrollLocks();
      $('html').removeClass('_side-hidden');

      setTimeout(() => {
        $('body .root').css({
          'margin-right': '0',
        });
      }, 0);
    },
  };

  if (methods[method]) {
    return methods[method].apply(
      this,
      Array.prototype.slice.call(arguments, 1)
    );
  } else if (typeof method === 'object' || !method) {
    return methods.init.apply(this, arguments);
  } else {
    $.error('Метод с именем ' + method + ' не существует для disableScroll');
  }
}

export function translate(alias, defaultt) {
  if ('i18n' in window && alias !== undefined && i18n[alias]) {
    return i18n[alias];
  } else if (defaultt !== undefined) {
    return defaultt;
  } else {
    return alias || '';
  } //console.log(alias);
}

export function banners(selector) {
  try {
    advFn.bannersInit(selector);
  } catch (e) {
    if ('console' in window) {
      console.log('Banners init: ' + e);
    }
  }
}

let adSkinsFlags = {
  phone: true,
  pad: true,
  desktop: true,
};

function getImageHeight(src) {
  return new Promise(function(resolve, reject) {
    const image = new Image();
    image.src = src;
    image.onload = function() {
      const height = this.height;
      resolve(height);
    }
    image.onerror = function() {
      reject("Unable to get the image height.");
    }
  });
}

export function adSkinsInit(params) {
  if (params && typeof params == 'object') {
    var _out = [],
      bodyClass = '',
      windowWidth = window.innerWidth || document.documentElement.clientWidth;

    const $adSizer = $('.pageAdSkin .adSizer');

    const skinOpenSpeedSec = 1.5;

    _out.push('<style type="text/css">');
    _out.push(`
      .pageAdSkin .adSizer {
        position: relative;
        transition: max-height ${skinOpenSpeedSec}s linear;
      }
    `);

    const phoneMaxPortrait = 414;
    const phoneMaxLandscapeMin = phoneMaxPortrait + 1;
    const phoneMaxLandscape = 767;
    if (
      windowWidth <= phoneMaxLandscape &&
      (params.IMAGE_PT ||
        params.IMAGE_PT_X2 ||
        params.IMAGE_LS ||
        params.IMAGE_LS_X2 ||
        params.VIDEO)
    ) {
      if (!adSkinsFlags.phone) {
        return;
      }

      // if(params.BACKGROUND_COLOR){
      //     _out.push('@media  only screen and (max-width: ${phoneMaxLandscape}px){');
      //     _out.push('.msnt-show-skin--phone .root{');
      //     _out.push('background-color:' + params.BACKGROUND_COLOR + ';');
      //     _out.push('}');
      //     _out.push('}');
      // }
      _out.push(`@media only screen and (max-width: ${phoneMaxPortrait}px) and (orientation: portrait) {`);
      if (params.IMAGE_PT) {
        _out.push(`
          .msnt-show-skin--phone .pageAdSkin {
            display: block;
          }
          .msnt-show-skin--phone .pageAdSkin .pageAdSkinUrl {
            background-image: url(${params.IMAGE_PT});
            background-size: 100% auto;
          }
        `);
      }
      if (params.IMAGE_PT_X2) {
        _out.push(`
          @media (-webkit-min-device-pixel-ratio: 2),
          (min--moz-device-pixel-ratio: 2),
          (min-device-pixel-ratio: 2) {
            .msnt-show-skin--phone .pageAdSkin {
              display:block;
            }
            .msnt-show-skin--phone .pageAdSkin .pageAdSkinUrl {
              background-image: url(${params.IMAGE_PT_X2});
            }
          }
        `);
      }
      if (params.HEIGHT_PT) {
        _out.push(`
          .msnt-show-skin--phone .pageAdSkin .adSizer img {
            width: ${params.HEIGHT_PT}%;
            max-width: none;
          }
        `);
      }
      _out.push(`}`)

      _out.push(`@media only screen and (min-width: ${phoneMaxLandscapeMin}px) and (max-width: ${phoneMaxLandscape}px) and (orientation: landscape) {`);
      if (params.IMAGE_LS) {
        _out.push(`
          .msnt-show-skin--phone .pageAdSkin {
            display: block;
          }
          .msnt-show-skin--phone .pageAdSkin .pageAdSkinUrl {
            background-image: url(${params.IMAGE_LS});
            background-size: 100% auto;
          }
        `);
      }
      if (params.IMAGE_LS_X2) {
        _out.push(`
          @media (-webkit-min-device-pixel-ratio : 2),
          (min--moz-device-pixel-ratio : 2),
          (min-device-pixel-ratio : 2) {
            .msnt-show-skin--phone .pageAdSkin {
              display:block;
            }
            .msnt-show-skin--phone .pageAdSkin .pageAdSkinUrl {
              background-image: url(${params.IMAGE_LS_X2});
            }
          }
        `);
      }
      if (params.HEIGHT_LS) {
        _out.push(`
          .msnt-show-skin--phone .pageAdSkin .adSizer img {
            width: ${params.HEIGHT_LS}%;
            max-width: none;
          }
        `);
      }
      _out.push(`}`)

      bodyClass = 'msnt-show-skin--phone';
      adSkinsFlags.phone = false;
    }

    const tabletMin = 768;
    const tabletMax = 1024;

    if (
      windowWidth >= tabletMin &&
      windowWidth <= tabletMax &&
      (params.IMAGE_PT ||
        params.IMAGE_PT_X2 ||
        params.IMAGE_LS ||
        params.IMAGE_LS_X2)
    ) {
      if (!adSkinsFlags.pad) {
        return;
      }

      _out.push(`@media only screen and (min-width: ${tabletMin}px) and (max-width: ${tabletMax}px) {`);
      if (params.BACKGROUND_COLOR) {
        _out.push(`.msnt-show-skin--pad .root {
          background-color: ${params.BACKGROUND_COLOR};
        }`);
      }

      _out.push(`@media (orientation: portrait) {`);
      if (params.IMAGE_PT) {
        _out.push(`
          .msnt-show-skin--pad .pageAdSkin .pageAdSkinUrl {
            background-image: url(${params.IMAGE_PT});
          }
        `);
      }
      if (params.IMAGE_PT_X2) {
        _out.push(`
          @media (-webkit-min-device-pixel-ratio : 2),
          (min--moz-device-pixel-ratio : 2),
          (min-device-pixel-ratio : 2) {
            .msnt-show-skin--pad .pageAdSkin .pageAdSkinUrl{
              background-image: url(${params.IMAGE_PT_X2});
            }
          }
        `);
      }
      if (params.HEIGHT_PT) {
        _out.push(`
          .msnt-show-skin--pad .pageAdSkin .adSizer img {
            width: ${params.HEIGHT_PT}%;
            max-width: none;
          }
        `);
      }
      _out.push('}');

      _out.push(`@media (orientation: landscape) {`);
      if (params.IMAGE_LS) {
        _out.push(`
          .msnt-show-skin--pad .pageAdSkin .pageAdSkinUrl {
            background-image: url(${params.IMAGE_LS});
          }
        `);
      }
      if (params.IMAGE_LS_X2) {
        _out.push(`
          @media (-webkit-min-device-pixel-ratio : 2),
          (min--moz-device-pixel-ratio : 2),
          (min-device-pixel-ratio : 2) {
            .msnt-show-skin--pad .pageAdSkin .pageAdSkinUrl{
              background-image: url(${params.IMAGE_LS_X2});
            }
          }
        `);
      }
      if (params.HEIGHT_LS) {
        _out.push(`
          .msnt-show-skin--pad .pageAdSkin .adSizer img {
            width: ${params.HEIGHT_LS}%;
            max-width: none;
          }
        `);
      }
      _out.push('}');
      _out.push('}');
      bodyClass = 'msnt-show-skin--pad';
      adSkinsFlags.pad = false;
    }

    const desktopMin = 1025;

    if (windowWidth >= desktopMin && params.IMAGE) {
      if (!adSkinsFlags.desktop) {
        return;
      }
      
      _out.push(`@media only screen and (min-width: ${desktopMin}px) {`);
      if (params.BACKGROUND_COLOR) {
        _out.push(`
          .msnt-show-skin--desktop .root{
            background-color: ${params.BACKGROUND_COLOR};
          }
        `);
      }
      if (params.HEIGHT) {
        _out.push(`
          .msnt-show-skin--desktop .pageAdSkin .adSizer{
            height: ${params.HEIGHT}px;
          }
        `);
      }
      _out.push(`
        .msnt-show-skin--desktop .pageAdSkin .pageAdSkinUrl{
          background-image: url(${params.IMAGE});
        }
      }`);
      bodyClass = 'msnt-show-skin--desktop';

      const imageSrc = params.IMAGE;
      const skinElement = document.querySelector('.pageAdSkin');

      function addStylesToSkin (skinElement, height, bgScroll) {
        if (bgScroll) {
          skinElement.style.position = 'relative';
          skinElement.style.top = 0;
          skinElement.style.left = 0;
          skinElement.style.height = height + 'px';
          skinElement.style.marginBottom = -(height - params.HEIGHT) + 'px';
        } else {
          skinElement.style.position = 'sticky';
          skinElement.style.height = height + 'px';
          skinElement.style.marginBottom = -(height - params.HEIGHT) + 'px';
          skinElement.style.bottom = 'auto';
          skinElement.style.left = 0;
          skinElement.style.top = Math.min(window.innerHeight - height, 0) + 'px';
          skinElement.classList.add('pageAdSkinSticky');
        }
      }

      getImageHeight(imageSrc)
        .then(function (height) {
          window.addEventListener('resize', () => {
            if (!params.BACKGROUND_FIXED) {
              addStylesToSkin(skinElement, height, params.BACKGROUND_SCROLL);
            }
          });
          if (!params.BACKGROUND_FIXED) {
            addStylesToSkin(skinElement, height, params.BACKGROUND_SCROLL);
          }
        })
        .catch(function (error) {
          console.error(error);
        });

      if (params.BACKGROUND_FIXED) {
        bodyClass += ' ' + 'msnt-show-skin--fixed';
      }
      adSkinsFlags.desktop = false;
    }

    _out.push('</style>');

    if (bodyClass) {
      var $headerAd = $('.m1-header-ad');
      if ($headerAd.length == 0) {
        $headerAd =  $('.m1-header-ad_infinite-article');
      }
      var $skinWrapper = $('.msnt-skin');
      var $targetBlock = $skinWrapper.find('.adSizer');
      var $targetBlockImg = $targetBlock.find('img');

      var hasActiveTrasition = false;

      $targetBlock
        .on('transitionstart', function() {
           hasActiveTrasition = true;
        })
        .on('transitionend', function() {
          $targetBlock.css({
            maxHeight: 'none',
          });

          $(document.documentElement).trigger('msnt-skin-init', [params]);
        });

      // this is required to initialize skin even if final hieght is the same
      setTimeout(function() {
        if (hasActiveTrasition) return;
        $(document.documentElement).trigger('msnt-skin-init', [params]);
      }, 100);

      var $page_skin_top = $('#page_skin_top');
      if (!$page_skin_top.length) return;

      // this should happend before skin classes are added and block has no height
      const headerAdHeight = $headerAd.outerHeight();

      $('body').addClass(bodyClass).removeClass('m1-fullwidth-layout');

      if (params.CLICK_URL) {
        var $pageAdSkinUrl = $('.pageAdSkinUrl');
        $pageAdSkinUrl.attr('href', params.CLICK_URL);
        if (params.CLICK_URL_TARGET) {
          $pageAdSkinUrl.attr('target', params.CLICK_URL_TARGET);
        } else {
          $pageAdSkinUrl.attr('target', '_blank');
        }
      }
      $('body').append(_out.join(''));

      // this is required for correct ad viewability tracking
      if (params.DVTAG) {
        // don't insert script with jQuery, it will add script copy to head, which breaks it's logic
        var script = document.createElement('script');
        script.src = params.DVTAG;
        script.type = 'text/javascript';
        $adSizer[0].appendChild(script);
      }

      lightHeader(params.MENU_STYLE === undefined ? true : false);

      if ('createEvent' in document) {
        var resizeEvent = document.createEvent('Event');
        resizeEvent.initEvent('resize', true, true);
        window.dispatchEvent(resizeEvent);
      } else {
        $(window).trigger('resize');
      }

      initAdSkinsStickyBanner();

      // image is hidden on desktop
      if ($targetBlockImg.is(':visible')) {
        $targetBlock.css({
          maxHeight: headerAdHeight + 'px',
        })
        // this is required to initialize skin even if final hieght is the same
        setTimeout(() => {
          $targetBlock.css({
            maxHeight: $targetBlockImg.outerHeight() + 'px'
          })
        }, 10)
      }
    }
  }
}

export function initAdSkinsStickyBanner() {
  var stickyVisibleThreshold = 400;
  var isStickyHidden = false;

  var stickyBannerTimerExpired = false;
  const bannerHideTimout = 5000;

  var $body = $(document.body);
  var $window = $(window);
  var $targetBlock = $('.msnt-show-skin--phone .pageAdSkin');
  var stickyHiddenCls = 'showAdSkinHiddenPhone';

  var m1Height = $('.m1-header-main').outerHeight() || 0;
  var brHeader = $('#barrauol').outerHeight() || 0;
  var headerHeight = m1Height + brHeader;
  var style = ['<style>',
    '@media screen and (max-width: 767px) {',
      '.msnt-show-skin--phone .pageAdSkin {',
        'position: sticky;',
        'top: ' + headerHeight + 'px;',
        'z-index: 100;',
        'background-color: var(--m1-it-main-bg-color, rgb(37, 37, 37));',
        'opacity: 1;',
        'transition: opacity 0.5s linear, transform var(--m1-header-transition-speed) ease var(--m1-header-transition-delay);',
      '}',

      '.headerStick.headerStick-hide.msnt-show-skin--phone .pageAdSkin {',
        'transform: translate3d(0, -' + headerHeight + 'px, 0);',
      '}',

      '.headerStick.msnt-show-skin--phone .m1-header-main:hover ~ .pageAdSkin,',
      '.headerStick.footerStick.msnt-show-skin--phone .pageAdSkin {',
        'transition-delay: 0s;',
        'transform: translate3d(0, 0, 0);',
      '}',

      '.msnt-show-skin--phone .pageAdSkin.'+ stickyHiddenCls +' {',
        'opacity: 0;',
        'pointer-events: none;',
      '}',
    '}',
  '</style>'].join('');

  const updateBannerVisibility = () => {
    if (!stickyBannerTimerExpired) return;

    var currentScrollY = window.scrollY;

    if (currentScrollY > stickyVisibleThreshold) {
      if (isStickyHidden) return;
      isStickyHidden = true;

      $targetBlock.addClass(stickyHiddenCls);
    } else {
      if (!isStickyHidden) return;
      isStickyHidden = false;

      $targetBlock.removeClass(stickyHiddenCls);
    }
  }

  $window.on('scroll', updateBannerVisibility);

  setTimeout(() => {
    stickyBannerTimerExpired = true;
    updateBannerVisibility();
  }, bannerHideTimout);

  $body.append(style);
}

export function adSkins(params) {
  try {
    adSkinsInit(params);
  } catch (e) {
    if ('console' in window) {
      console.log('Skin init: ' + e);
    }
  }
}

export function adSkinsTest() {
  try {
    adSkinsInit(advFn.advtest_params);
  } catch (e) {
    if ('console' in window) {
      console.log('Skin init: ' + e);
    }
  }
}

export function adGallerySkin(params) {
  try {
    adGallerySkinInit(params);
  } catch (e) {
    if ('console' in window) {
      console.log('Skin gallery init: ' + e);
    }
  }
}

export function adGallerySkinInit(params) {
  if (params && typeof params == 'object') {
    var imageSRC = false;
    if (params.IMAGE) {
      imageSRC = params.IMAGE;
      if (window.devicePixelRatio >= 2 && params.IMAGE_X2) {
        imageSRC = params.IMAGE_X2;
      }
    }
    if (imageSRC) {
      var href = '';
      if (params.CLICK_URL) {
        href = `href="${params.CLICK_URL}"`;
      }
      var html = `
      <div style="position: absolute; top:0;left:0;right:0;z-index:10;text-align:center;">
        <a ${href} target="_blank" style="width: 100%;max-width:375px;display:inline-block;">
          <img style="width:100%" src="${imageSRC}" alt="" />
        </a>
      </div>`;
      var $lgTopsideBox = $('.lg-topside-box');
      // var $skinHolder = $('.skin-holder');
      if ($lgTopsideBox.length) {
        var $banner = $lgTopsideBox.find('.apb.mleaderboard .ap');
        if ($banner.length) {
          $banner.html(html);
        }
        // $skinHolder.html(html);
        // $skinHolder.attr('data-init', 'true');
      }
    }
  }
}

// toggleMenuDrawerWithAnimationOpenTimeouts: [],
let toggleMenuDrawerWithAnimationOpenTimeouts = [];
export function toggleMenuDrawerWithAnimation(isOpen) {
    const $mainMenuDrawer = $('.m1-drawer');

    const $animateBlocks = $mainMenuDrawer.find(
      '.m1-navigation-main__animated-block'
    );

    const elsWithHeight = $animateBlocks
      .get()
      .map((item, index) => [index, $(item).offset().top]);

    elsWithHeight.sort((a, b) => (a[1] > b[1] ? 1 : -1));

    const ANIMATION_DELAY = 100;

    let offset = 0;
    elsWithHeight.forEach((data, index) => {
      const $item = $animateBlocks.eq(data[0]);
      if ($item.is(':hidden')) {
        offset++;
      }

      if (isOpen) {
        toggleMenuDrawerWithAnimationOpenTimeouts.push(
          setTimeout(() => {
            $item.addClass('show').removeClass('hide');
          }, (index - offset) * ANIMATION_DELAY)
        );
      } else {
        toggleMenuDrawerWithAnimationOpenTimeouts.forEach(item => {
          clearTimeout(item);
        });
        toggleMenuDrawerWithAnimationOpenTimeouts = [];
        $item.addClass('hide').removeClass('show');
      }
    });
}

export function connectThirdPartyServices(arr) {
  if (arr.length) {
    for (var i = 0, c = arr.length; i < c; i++) {
      if (!arr[i][1]) {
        continue;
      }
      getScript(arr[i][0], arr[i][2] || false);
    }
  }
}

export function highresDisplay() {
    if (window.matchMedia) {
      var mq = window.matchMedia(
        'only screen and (-moz-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)'
      );
      if (mq && mq.matches) {
        return true;
      }
    }
}

export function trackSocial(network, action, url) {
  gtmDataLayer.push({
    event: 'socialInteraction',
    socialNetwork: network,
    socialAction: action,
    socialTarget: url,
  });
}

export function afterShare(data, conteinerData) {
  if (
    'gtmDataLayer' in window &&
    data.officialName &&
    conteinerData.shareUrl
  ) {
    this.trackSocial(data.officialName, 'share', conteinerData.shareUrl);
  }

  if (data.collect && data.name && data.entityType && data.entityId) {
    $.post({
      url: msConfigVars.viewsServiceShareUrl,
      data: JSON.stringify({
        network: data.name,
        id: data.entityId,
        type: data.entityType,
      }),
    });
    //.done(function(response){
    //     console.log(response);
    // });
  }
}

// export function showLoginWindow() {
//   if ('msnt' in window && typeof msnt.openLoginWindow === 'function') {
//     msnt.openLoginWindow();
//     return true;
//   }
//   return false;
// }

// export function showRegisterPopup(params) {
//   $.post('/register/', function (response) {
//     $.cookie('reglb', 1, {expires: 3, path: '/'});

//     cmpDialog.content(
//       response.html,
//       null,
//       function onClose() {
//         var dialog = this;

//         if (!dialog.isUserRegistered) return;

//         window.location.reload();
//       },
//       'registerDialog',
//       function afterVisible() {
//         var dialog = this,
//           $container = dialog.$contayner,
//           $form = $container.find('form');

//         if (params && params.email) {
//           $form.find('input[name="email"]').val(params.email);
//         }

//         $form.validate();

//         forms['config']['register_form'] = {
//           preventAlert: true,
//           preventUrl: true,
//         };

//         forms['statusFalse']['register_form'] = function (
//           $form,
//           validator,
//           response
//         ) {
//           $('#regenerate_captcha').trigger('click');

//           if (response.message) {
//             cmpTrayAlert.show(response.message, 'error');
//           }
//         };

//         forms['statusTrue']['register_form'] = function (
//           $form,
//           validator,
//           response
//         ) {
//           $container.addClass('registered');
//           dialog.isUserRegistered = true;
//         };
//       }
//     );
//   });
// }

// export function showRegisterFooter() {
//   var $footer = $('#register_footer_wrapper'),
//     $closeBtn = $footer.find('.close'),
//     $form = $footer.find('form'),
//     $emailField = $form.find('input[type="email"]'),
//     registerFormLoading = false;

//   $footer.addClass('visible');

//   $form.validate({
//     errorPlacement: function (error, element) {
//       element.attr('title', error.text());
//     },
//     submitHandler: function () {
//       if (registerFormLoading) return;

//       registerFormLoading = true;

//       hideRegisterFooter();

//       showRegisterPopup({
//         email: $emailField.val(),
//       });
//     },
//   });

//   $closeBtn.on('click', hideRegisterFooter);

//   function hideRegisterFooter() {
//     $closeBtn.off('click');

//     $.cookie('regft', 1, {path: '/'});

//     $footer.fadeOut(function () {
//       $footer.remove();
//     });
//   }
// }

export function movieDirection(sX, sY, eX, eY) {
  var angle = 0,
    X = sX - eX,
    Y = eY - sY,
    Z = Math.round(Math.sqrt(Math.pow(X, 2) + Math.pow(Y, 2))), //the distance - rounded - in pixels
    r = Math.atan2(Y, X); //angle in radians (Cartesian system)

  angle = Math.round((r * 180) / Math.PI); //angle in degrees

  if (angle < 0) {
    angle = 360 - Math.abs(angle);
  }
  if (angle <= 45 && angle >= 0) {
    direction = 'left';
    distance = sX - eX;
  } else if (angle <= 360 && angle >= 315) {
    direction = 'left';
    distance = sX - eX;
  } else if (angle >= 135 && angle <= 225) {
    direction = 'right';
    distance = eX - sX;
  } else if (angle > 45 && angle < 135) {
    direction = 'down';
    distance = eY - sY;
  } else {
    direction = 'up';
    distance = sY - eY;
  }
  return {direction: direction, distance: distance};
}

export function filterDropdownList(selector) {
    var $filters_box = $(selector);
    if ($filters_box.length) {
      var $box = $filters_box.find('.filter-dropdown-list'),
        $list = $box.find(".filter-list");
      if ($list.length) {
        var list_width = $list.width(),
          elements_width = 0,
          is_columns = $list.data('columns') || $list.is('.columns');
        if (is_columns) {
          $list.data('columns', true);
        }
        $list.find('> a').each(function(index, element) {
          elements_width += $(this).outerWidth();
        });
        if (list_width) {
          if (elements_width > list_width) {
            $box.removeClass('hidden-button');
            if (is_columns) {
              $list.addClass('columns');
            }
          } else {
            $box.addClass('hidden-button');
            if (is_columns) {
              $list.removeClass('columns');
            }
          }
        }
      }
    }
}

export function shareToLightStyle(selector) {
    var $box = $(selector);
    if ($box.length) {
      var $boxShare = $box.find('.shareBox');
      if (document.documentElement.clientWidth < 768) {
        $boxShare.removeClass('light');
      } else {
        $boxShare.addClass('light');
      }
    }
}

export function getSharesGraphPoints(svg, shares) {
    var res = [],
      lineThickness = 2,
      x = lineThickness,
      width = svg.width() - lineThickness,
      height = svg.height() - lineThickness * 2,
      sharesCount,
      step,
      maxVal = 0,
      koef;

    if (!shares) return res;

    sharesCount = shares.length;
    step = width / (sharesCount - 1);

    shares.forEach(function (v, k) {
      var p = (v / shares[sharesCount - 1]) * 1000,
        pl = k > 0 ? (shares[k - 1] / shares[sharesCount - 1]) * 1000 : 0,
        val = k > 0 ? p - pl : 0;

      res.push({
        x: x,
        y: val,
      });

      x += step;
    });

    res.forEach(function (v, k) {
      res[k].y =
        k > 0 && k < res.length - 1
          ? (res[k - 1].y + res[k].y + res[k + 1].y) / 3
          : res[k].y; // middle
      if (maxVal < res[k].y) {
        maxVal = res[k].y;
      }
    });

    koef = height / maxVal;

    res.forEach(function (v, k) {
      res[k].y = res[k].y ? res[k].y * koef - lineThickness : 0;
      res[k].x -= lineThickness / 2;
    });

    return res;
}

export function pushGtmDataFromElement(options) {
  options = $.extend(
    {
      selector: null,
    },
    options
  );

  if (options.selector) {
    var $container = $(options.selector);
    if ($container.length) {
      $container.each(function () {
        var data = $(this).data();
        if (data) {
          gtmDataLayer.push(data);
        }
      });
    }
  }
}

export function gtmInsertTagInScrollAppearedElement(containerId, options) {
  if (
    options.tag &&
    options.entityId &&
    options.entityType &&
    'google_tag_manager' in window &&
    google_tag_manager[containerId]
  ) {
    var mngDL = google_tag_manager[containerId].dataLayer;
    var eventName = mngDL.get('event');
    var gtm = mngDL.get('gtm');
    if (eventName == 'gtm.elementVisibility' && gtm && gtm.element) {
      var $container = $(gtm.element);
      if ($container.length) {
        var data = $container.data();
        if (
          options.entityId == data.entityId &&
          options.entityType == data.entityType
        ) {
          if (options.insert == 'before') {
            $container.prepend(options.tag);
          } else {
            $container.append(options.tag);
          }
          //console.log(options, data);
        }
      }
    }
  }
}

export function hitPageView(url, title) {
  if ('gtmDataLayer' in window) {
    var url =
      url ||
      window.location.pathname +
      window.location.search +
      window.location.hash;
    var title = title || document.title;
    gtmDataLayer.push({
      event: 'VirtualPageview',
      virtualPageURL: url,
      virtualPageTitle: title,
    });
  }
}

export function lightHeader(light) {
  if (light) {
    $(document.body).addClass('lightHeader');
  } else {
    $(document.body).removeClass('lightHeader');
  }
}

export function getScript(url, callback, cache, errorCallback) {
  $.ajax({
    type: 'GET',
    url: url,
    success: callback,
    dataType: 'script',
    error: errorCallback,
    cache: cache === false ? false : true,
  });
}

let exportGetScript = {
  getScript: function(url, callback, cache, errorCallback) {
    $.ajax({
      type: 'GET',
      url: url,
      success: callback,
      dataType: 'script',
      error: errorCallback,
      cache: cache === false ? false : true,
    });
  }
}

let exportGetScriptNew = {
  getScript: function(url, callback, cache, errorCallback) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.text = xhr.responseText;
          document.head.appendChild(script);
          if (callback && typeof callback === 'function') {
            callback();
          }
        } else if (errorCallback && typeof errorCallback === 'function') {
          errorCallback(xhr.statusText);
        }
      }
    };
    xhr.send(null);
  }
};

export {exportGetScript, exportGetScriptNew}

export function initEmbeds($wrapper) {
  $wrapper.find('.embed-item').each(function(index, element) {
    var $el = $(element),
      code = $el.data('embedCode');
        if (code) {
          $el.html(decodeURIComponent(code));
        }
  });
}

export function connectDependentSelect2Fields (
  $field,
  $dependentField,
  idProp,
  $submitBtn,
  dataParam
) {
  var selectData = $field.data();
  var queryParams = new URLSearchParams(dataParam).toString();

  if (selectData.make) {
    if (selectData.ready || !selectData.url) return;
  
    const fetchUrl = `${selectData.url}?${queryParams}`;

    fetch(fetchUrl, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('smth went wrong');
      }
    })
    .then(data => {
      var items = $.map(data.items, function (item) {
        return {
          text: item.text,
          id: item[idProp],
        };
      });

      items.unshift({
        id: ' ',
        text: selectData.placeholder,
      });

      var val = $field.val();
      $field.empty().select2({
        data: items, 
        id: 'id',
        dropdownParent: $('.root'),
      });

      if (val) {
        $field.val(val);
      }

      setTimeout(() => {
        $field.val(selectData.make).trigger('change');
      }, 100);

      $field.data('ready', true);
    })
  }

  $field
    .select2({
      placeholder: i18n.select_make,
      dropdownParent: $('.root'),
      language: {
        errorLoading: function () {
          return i18n.no_results;
        },
        loadingMore: function () {
          return i18n.loading;
        },
        noResults: function () {
          return i18n.no_results;
        },
        searching: function () {
          return i18n.loading;
        },
      },
    })
    .on('select2:open', function (event) {
      setTimeout(function () {
        $('.select2-search input').blur();
      }, 1);
    })
    .on('select2:opening', function () {
      var selectData = $field.data();
      // var makeId = window.sessionStorage.getItem('makeId');

      if (selectData.ready || !selectData.url) return;
      
      const fetchUrl = `${selectData.url}?${queryParams}`;
      fetch(fetchUrl, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(data => {
        var items = $.map(data.items, function (item) {
          return {
            text: item.text,
            id: item[idProp],
          };
        });

        items.unshift({
          id: ' ',
          text: selectData.placeholder,
        });

        var val = $field.val();
        $field.empty().select2({
          data: items, id: 'id',
          dropdownParent: $('.root'),
        });

        if (val) {
          $field.val(val);
        }

        $field.data('ready', true);
        $field.select2('open');
      })
      .catch(error => {
        console.log(error);
      })
    })
    .on('change', function (event) {
      var $select = $(this),
        value = $select.val();

      if (value && value !== ' ') {
        var selectData = $dependentField.data();

        $submitBtn.attr('disabled', true);

        if (!selectData.url) return;

        var data = dataParam || {};
        data[idProp] =
          value.indexOf('_') !== -1 ? value.split('_')[0] : value;

        queryParams = new URLSearchParams(data).toString();
        const fetchUrl = `${selectData.url}?${queryParams}`;
        fetch(fetchUrl, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Something went wrong');
          }
        })
        .then(data => {
          var items = data.items;
            items.unshift({
              id: ' ',
              text: $dependentField.data('placeholder'),
            });
            $dependentField
              .empty()
              .select2({
                data: items,
                dropdownParent: $('.root'),
              })
              .prop('disabled', false)
              .data('ready', true);

            if (selectData.submodel) {
              setTimeout(() => {
                $dependentField.val(selectData.submodel).trigger('change');
              }, 100);
            }

            $submitBtn.prop('disabled', '');
        })
        .catch(error => {
          console.log(error);
        })
      } else {
        $dependentField
          .val(' ')
          .empty()
          .trigger('change')
          .prop('disabled', true);
        $submitBtn.attr('disabled', true);
      }
    });
}

export function listMorePage($button, offset, preventReplaceState) {
  if (!jsVars.ui.auto_infinite_scroll) {
    return;
  }
  if ($button.length) {
    var windowHeight = document.documentElement.clientHeight,
      button_rect = $button[0].getBoundingClientRect();
    if (button_rect.top - windowHeight <= (offset || 0)) {
      $button.trigger('click').removeAttr('data-button');
      // if (preventReplaceState) {
      //   return;
      // }
      // if (typeof history.replaceState == "function") {
      //   var url = $button.attr("href");
      //   if (url) {
      //     history.replaceState(null, null, url);
      //   }
      // }
    }
  }
}

export function initializeWebComponents () {
  window.pendingWebcomponentsInitialization = true;
  var script = document.createElement('script');

  script.src =
    'https://s3.amazonaws.com/motor1-v2.com-cdn/custom/polymer2/webcomponentsjs/webcomponents-loader.js';

  document.body.appendChild(script);
}

export function appendPolymerElement(href, isDevEnv) {
  var link = document.createElement('link'),
    es5Adapter = document.createElement('script');
  link.rel = 'import';
  link.href = href;

  if (!isDevEnv) {
    es5Adapter.src =
      '//cdn.motor1.com/custom/polymer2/webcomponentsjs/custom-elements-es5-adapter.js';

    document.head.appendChild(es5Adapter);
  }

  if (!window.polymerWebAnimationPolyfillLoaded) {
    var scriptAnim = document.createElement('script');
    scriptAnim.src =
      'https://s3.amazonaws.com/motor1-v2.com-cdn/custom/polymer2/web-animations-js/web-animations-next-lite.min.js';
    document.body.appendChild(scriptAnim);
  }

  setTimeout(() => {
    document.body.appendChild(link);
  }, 0);
}

export function handleGetQuoteBtnClick(el) {
  if (window.pendingWebcomponentsInitialization) return;

  if (el.classList.contains('waiting')) return;

  var data = el.dataset,
    isDev = document.body.classList.contains('development'),
    webComponentsSupported =
      'registerElement' in document &&
      'import' in document.createElement('link') &&
      'content' in document.createElement('template'),
    params = {
      detail: {
        id: data.inventoryId,
        leadParams: JSON.parse(data.leadParams),
        section: data.section,
      },
    };

  var getQuoteElRef, checkElementReadyInterval;

  if (!window.loadedComponents || !window.loadedComponents.getQuote) {
    window.loadedComponents = window.loadedComponents || {};
    window.loadedComponents.getQuote = true;

    el.classList.add('waiting');

    let srcM1GetQuote =
      '/polymer2/' + (!isDev ? 'build/' : '') + 'src/m1-get-quote.html';

    appendPolymerElement(srcM1GetQuote, isDev);
  }

  getQuoteElRef = document.getElementsByTagName(
    'm1-check-availability-response-dialog'
  );

  if (webComponentsSupported || window.webcomponentsInitialized) {
    // we check for method on element
    // this will tell us is component initialized
    if (typeof getQuoteElRef[0].onGetQuote === 'function') {
      document.dispatchEvent(new CustomEvent('get-quote', params));
      el.classList.remove('waiting');
    } else {
      checkElementReadyInterval = setInterval(function () {
        if (typeof getQuoteElRef[0].onGetQuote === 'function') {
          clearInterval(checkElementReadyInterval);
          document.dispatchEvent(new CustomEvent('get-quote', params));
          el.classList.remove('waiting');
        }
      }, 200);
    }
  } else {
    el.classList.add('waiting');

    window.addEventListener('WebComponentsReady', function () {
      window.pendingWebcomponentsInitialization = false;
      window.webcomponentsInitialized = true;

      document.dispatchEvent(new CustomEvent('get-quote', params));
      el.classList.remove('waiting');
    });

    initializeWebComponents();
  }
}

export function goToUrl(href) {
  if (href) {
    window.location.href = href;
  } else {
    window.location.reload();
  }
}

export function goToUrlInANewTab(href, target) {
  if (target) {
    window.open(href, target);
  } else {
    window.location.href = href;
  }
}

export function lloadDisqus() {
  var $comments = $comments = $('#disqus_thread');
  var $comments_apb = $('.comments-container');
  var $disqusWrapper = $('.disqus-wrapper');
  if ($disqusWrapper.length > 0) {
    var data = $comments.data(),
      time = new Date(),
      id = time.getTime(),
      title = encodeURI(data.title);

    var tpl_disqus = `<iframe id="disqus_comments_${id}" 
                src="/disqus/?id=${id}&cid=${data.cid}&url=${data.url}&title=${title}" 
                style="width:100%;height:100%"  
                frameborder="0" 
                scrolling="no" 
                marginheight="0" 
                marginwidth="0" 
                allowtransparency="true" 
                hspace="0" 
                vspace="0"
                ></iframe>`;

    $(tpl_disqus).appendTo($disqusWrapper);
    // banners($comments_apb);
  }
}

export function bigImgLazyload(noAutoplay) {
  const options = {
    root: null,
    rootMargin: '20%',
    threshold: 0.1,
  };
  let cpCookieExists = document.cookie.indexOf('_cpauthhint');
  const contentPassCountries = ['fr', 'uk', 'ar', 'us', 'it', 'me', 'tr', 'es', 'id'];
  let currentEdition = document.querySelector('body').dataset.edtn;
  let lazyElements = [].slice.call(document.querySelectorAll('.lazy')),
    lazyElementObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyElement = entry.target,
            iframe = lazyElement.querySelector('iframe'),
            dataSrc = iframe?.dataset?.src;
          if (dataSrc) {
              let url = new URL(iframe.dataset.src);
/*            if(dataSrc.includes('autoplay') && noAutoplay){
              url.searchParams.has('autoplay');
              url.searchParams.delete('autoplay');
            }*/
            if((window.location.href.includes('adv=0') || (cpCookieExists != -1 && contentPassCountries.includes(currentEdition))) &&
              dataSrc.includes('motorsport.tv')){
              url.searchParams.append('no_ad','1');
            }
            if(dataSrc !== url.href){
              iframe.dataset.src = url.href
            }
            iframe.src = iframe.dataset.src;
          }
          lazyElement.classList.remove('lazy');
          lazyElementObserver.unobserve(lazyElement);
        }
      });
    }, options);

  lazyElements.forEach(function (lazyElement) {
    lazyElementObserver.observe(lazyElement);
  });
}

// export function hybridLazyLoad() {
//   if ("loading" in HTMLImageElement.prototype) {
//     var lazyPictures = document.querySelectorAll('.lazyload [data-srcset]');
//     var lazyImages = document.querySelectorAll('.lazyload [data-src]');

//     if (lazyPictures.length > 0) {
//       lazyPictures.forEach(function(lazyPicture){
//         lazyPicture.setAttribute('srcset', lazyPicture.getAttribute('data-srcset'));
//       })
//     }

//     if (lazyImages.length > 0) {
//       lazyImages.forEach(function(lazyImage){
//         lazyImage.setAttribute('src', lazyImage.getAttribute('data-src'));
//       })
//     }
//   } else {
//     let lazyImages = [].slice.call(document.querySelectorAll('.lazyload')),
//     lazyImagesObserver = new IntersectionObserver(function (entries) {
//       entries.forEach(function (entry) {
//         if (entry.isIntersecting) {
//           let lazyImage = entry.target,
//               sources = lazyImage.querySelectorAll('source'),
//               image = lazyImage.querySelector('img');
//           if (image || sources) {
//             image.src = image.dataset.src;
//             if (image.dataset.srcset) {
//               image.srcset = image.dataset.srcset;
//             }
//             sources.forEach(function (source) {
//               if (source && source.dataset && source.dataset.srcset) {
//                 source.srcset = source.dataset.srcset;
//               }
//             });
//           }

//           lazyImage.classList.remove('lazyload');
//           lazyImagesObserver.unobserve(lazyImage);
//         }
//       });
//     });

//     lazyImages.forEach(function (lazyImage) {
//       lazyImagesObserver.observe(lazyImage);
//     });
//   }
// }

export function bannerLazyload() {
  var targetLazyElements;
  if ((device && device.type === 'mobile')) {
    targetLazyElements = document.querySelectorAll('.ap[data-dfp-attrs*=\'"lazy":1\']:not([data-ready="true"])');
  } else if (document.querySelector('.root').id === 'page_index_articles_detail') {
    targetLazyElements = document.querySelectorAll('.sidebar .apb:not(:first-child) .ap[data-dfp-attrs*=\'"lazy":1\']:not([data-ready="true"])');
  }

  if (targetLazyElements) {
    for (var index = 0; index < targetLazyElements.length; index++) {
      var targetLazyElementsParentClasses = targetLazyElements[index].parentNode.classList;
      if (!targetLazyElementsParentClasses.contains('unobserve')
          && !targetLazyElementsParentClasses.contains('leaderboard')
          && !targetLazyElementsParentClasses.contains('super')) {
        targetLazyElements[index].parentNode.classList.add('has-hidden-child');
      }
    }

    let lazyBanners = [].slice.call(document.querySelectorAll('.has-hidden-child:not(.unobserve)'));
    let viewportHeight = window.innerHeight * 0.25;
    let options = {
      rootMargin: `${viewportHeight}px`,
      threshold: 0,
    }
    let lazyBannersObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyBanner = entry.target;
          let children = lazyBanner.childNodes;
          for (var i = 0; i < children.length; i++) {
            children[i].style.display = 'inline-block';
            banners(children[i]);
          }
          lazyBanner.classList.remove('has-hidden-child');
          lazyBanner.classList.add('unobserve');
          lazyBannersObserver.unobserve(lazyBanner);
        }
      });
    }, options);
    lazyBanners.forEach(function (lazyBanner) {
      lazyBannersObserver.observe(lazyBanner);
    });
  }
}

export function loadCommentsNumber() {
  let commentsNumberButtonFirst = [].slice.call(document.querySelectorAll('.postMeta-Top .commentsNumber'));
  let currentEdition = document.querySelector('body').dataset.edtn;

  let viewportHeight = window.innerHeight * 0.25;
  let options = {
    rootMargin: `${viewportHeight}px`,
    threshold: 0,
  }

  var disqusCommentsButtonObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        let commentsButtons = document.querySelectorAll('.commentsNumber');
        let commentsNumberButton = entry.target;
        let disqusRequestUrl = commentsNumberButton.dataset.disqusUrl;

        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            let data = this.responseText;
            let dataParsed = JSON.parse(data);
            let commentsNumber;

            if (dataParsed.items.length == 0) {
              commentsNumber = 0;
            } else {
              commentsNumber = dataParsed.items[0].comments
            }

            for (let item of commentsButtons) {
              if (item.hasAttribute('id')) {
                if (commentsNumber == 0) {
                  item.innerHTML = i18n.empty_comments_js;
                } else if (commentsNumber == 1) {
                  item.innerHTML = i18n.one_comment_js;
                } else if (commentsNumber > 1) {
                  let innerText = i18n.more_comments_js;
                  item.innerHTML = innerText.replace('%s%', commentsNumber);
                }
              } else {
                item.querySelector('.commentsNumber_num').innerHTML = commentsNumber;
              }
            }
          }
        };
        xhttp.open("GET", disqusRequestUrl, true);
        xhttp.send();
        disqusCommentsButtonObserver.unobserve(commentsNumberButton);
      }
    });
  }, options);

  commentsNumberButtonFirst.forEach(function (commentsNumberButton) {
    disqusCommentsButtonObserver.observe(commentsNumberButton);
  });
}

export function floating_share_box() {
  var $window = $(window),
    html = document.documentElement,
    body = document.body,
    $shareBoxContent = $('#share_box_content_wrapper'),
    $shareBox = $shareBoxContent.find('.shareBox'),
    shareBoxHidden = false,
    $wrapper;

  if ($shareBox.length) {
    $wrapper = $('<div>');
    $wrapper.addClass('hidden');

    $shareBox
      .attr('class', '')
      .addClass('shareBox share-box-icon-list')
      .appendTo($wrapper);
    $wrapper.append(
      '<span class="collapseBtn icon-angle-double-left"></span>'
    );
    $wrapper.append(
      '<span class="expandBtn icon-angle-double-right"></span>'
    );
    $wrapper.attr('id', 'floating_share_box').appendTo(document.body);

    setTimeout(function () {
      showHideFloatingBox();
      $wrapper.removeClass('hidden');

      setTimeout(function () {
        $wrapper.addClass('initialized');
      }, 1000);
    }, 2000);

    $window
      .on('scroll.sharebox', showHideFloatingBox)
      .on('resize.sharebox', showHideFloatingBox);

    $wrapper
      .on('click', 'a', function (event) {
        //var isHidden = parseInt($wrapper.css('left'), 10) !== 0;
        var isHidden = parseInt($wrapper.css('opacity'), 10) !== 1;

        if (isHidden) {
          event.preventDefault();
          event.stopPropagation();
        }
      })
      .on('click', '.collapseBtn', function () {
        $wrapper.addClass('collapsed');
      })
      .on('click', '.expandBtn', function () {
        $wrapper.removeClass('collapsed');
      });
  }

  function showHideFloatingBox() {
    var scrollTop = $window.scrollTop(),
      windowHeight = window.innerHeight,
      totalHeight,
      isHide = false;

    // share box display for desktop
    if (window.innerWidth > 767) {
      if (scrollTop < windowHeight) {
        isHide = true;
      }
      // share box display for mobile devides
    } else {
      totalHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      if (totalHeight - scrollTop - windowHeight < 100) {
        isHide = true;
      }
    }

    if (isHide) {
      if (shareBoxHidden) return;
      $shareBox.hide();
      shareBoxHidden = true;
    } else if (shareBoxHidden) {
      $shareBox.show();
      shareBoxHidden = false;
    }
  }
}

export function coralCommentsCountUpdate () {
  let currentEdtn = document.querySelector('body').dataset.edtn;
  let isCpUser = document.cookie.indexOf('_cpauthhint');
  let functionalCookieIsEnabled = window.msntConsent.isSocialsAllowed();
  if (currentEdtn === 'ra_us') {
    $('.coral-script').remove();
    window.CoralCount = undefined;
    const host = window.location.host;
    let coralEnviroment;
    if (host === 'www.rideapart.com') {
      coralEnviroment = 'coral';
    } else if (host === 'ra-s.motor1.com') {
      coralEnviroment = 's-coral';
    } else if (host === 'ra-q.motor1.com') {
      coralEnviroment = 'q-coral';
    } else {
      coralEnviroment = 'coral';
    }
    const script = `<script class="coral-script" src="https://${coralEnviroment}.rideapart.com/assets/js/count.js"></script>`;
    $("head").append(script);
  }
  if(currentEdtn === 'fr') {
    $('.coral-script').remove();
    window.CoralCount = undefined;
    let script;
    if (isCpUser !== -1 && functionalCookieIsEnabled) {
      script = '<script class="coral-script" src="https://fr-coral.motor1.com/assets/js/count.js"></script>';
    } 
    if (isCpUser == -1) {
      script = '<script class="coral-script" src="https://fr-coral.motor1.com/assets/js/count.js"></script>';
    }

    $("head").append(script);
  }
  if(currentEdtn === 'us') {
    $('.coral-script').remove();
    window.CoralCount = undefined;
    let script;
    if (isCpUser !== -1 && functionalCookieIsEnabled) {
      script = '<script class="coral-script" src="https://www-coral.motor1.com/assets/js/count.js"></script>';
    } 
    if (isCpUser == -1) {
      script = '<script class="coral-script" src="https://www-coral.motor1.com/assets/js/count.js"></script>';
    }
    
    $("head").append(script);
  }
  if(currentEdtn === 'ev_us') {
    $('.coral-script').remove();
    window.CoralCount = undefined;
    const script = '<script class="coral-script" src="https://us-coral.insideevs.com/assets/js/count.js"></script>';
    $("head").append(script);
  }
  if(currentEdtn === 'ev_br') {
    $('.coral-script').remove();
    window.CoralCount = undefined;
    const script = '<script class="coral-script" src="https://br-coral.insideevs.com/assets/js/count.js"></script>';
    $("head").append(script);
  }
  return;
}

export function commentsCountUpdate(rebuild) {
  if (rebuild) {
    if (
      'DISQUSWIDGETS' in window &&
      DISQUSWIDGETS.getCount &&
      typeof DISQUSWIDGETS.getCount == 'function'
    ) {
      DISQUSWIDGETS.getCount({reset: true});
    }
  }
  var bodyData = $(document.body).data(),
    spotimId = bodyData.spotimId;
  // if (spotimId) {
  //   //console.log(bodyData.spotimId);
  //   var $comments = $('.spot-im-replies-count:not([data-ready="1"])');

  //   if ($comments.length) {
  //     var ids = [];
  //     $comments.each(function () {
  //       ids.push($(this).data('postId'));
  //     });
  //     if (ids.length) {
  //       var sids = ids.join(',');
  //       $.ajax(
  //         'https://open-api.spot.im/v1/messages-count?spot_id=' +
  //         spotimId +
  //         '&posts_ids=' +
  //         sids
  //       ).done(function (response) {
  //         if (
  //           response &&
  //           response.constructor === Object &&
  //           response.messages_count
  //         ) {
  //           var $comment = [];
  //           //console.log(response.messages_count);

  //           for (var i in response.messages_count) {
  //             $comment = $(
  //               '.spot-im-replies-count[data-post-id="' + i + '"]'
  //             );
  //             if ($comment.length) {
  //               if (response.messages_count[i]) {
  //                 $comment.find('.value').html(response.messages_count[i]);
  //               }
  //               $comment.attr('data-ready="1"');
  //               $comment = [];
  //             }
  //           }
  //         }
  //       });
  //     }
  //   }
  // }
}

export function stickyContainer() {
  let $stick = $('.adv_container'),
    $body = $('body'),
    $bread = $('.pre-center-breadcrumbs'),
    offtop;

  if ($stick.length) {
    offtop = $stick.offset().top;
    $(window).on('scroll', function () {
      let scrtop = $(window).scrollTop();
      if (scrtop > offtop && $stick.hasClass('natural')) {
        $stick.removeClass('natural').addClass('fixed').css('top', 60);
      }
      if (scrtop > offtop && $body.hasClass('headerStick') && !$body.hasClass('footerStick')){
        $stick.addClass('to-top');
      }else{
        $stick.removeClass('to-top');
      }
      if (offtop > scrtop && $stick.hasClass('fixed')) {
        $stick.removeClass('fixed').addClass('natural').css('top', 350);
      }
    }).trigger('scroll');
  }

}

export function scaleFix() {
  var viewportmeta =
    document.querySelector &&
    document.querySelector('meta[name="viewport"]'),
    ua = navigator.userAgent,
    gestureStart = function () {
      viewportmeta.content =
        'width=device-width, minimum-scale=0.25, maximum-scale=1.6';
    },
    scaleFix = function () {
      if (viewportmeta && /iPhone|iPad/.test(ua)) {
        /* && !/Opera Mini/.test(ua)*/
        var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
        if (parseInt(v[1]) < 6) {
          viewportmeta.content =
            'width=device-width, minimum-scale=1.0, maximum-scale=1.0';
          document.addEventListener('gesturestart', gestureStart, false);
        }
      }
    };
  scaleFix();
}

export function clsHelper(){
  var clsScore = 0;

  try {
    var po = new PerformanceObserver(function(list) {
    var entries = list.getEntries();
    for (var i = 0; i < entries.length; i++) {
      if (!entries[i].hadRecentInput) {
        clsScore += entries[i].value;
      }
    }
  });

  po.observe({type: 'layout-shift', buffered: true});
  } catch (e) {
  // not supported
  }
}

//gtmDataLayer connected functions
export function gaTrackSocial(network, action, url) {
  if ('gtmDataLayer' in window) {
    gtmDataLayer.push({
      event: 'socialInteraction',
      socialNetwork: network,
      socialAction: action,
      socialTarget: url,
    });
  }
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function initCoralComments () {
  const commentsContainer = document.querySelector('#coral_thread');
  const commentsSkeleton = commentsContainer.querySelector('.coral_placeholder__wrapper');
  commentsSkeleton.style.display = 'block';
  let accessToken = null;
  let colorSheme = window.htmlElement.dataset.colorScheme;
  const data = commentsContainer.dataset;

  const {baseUrl, embedPath, currentEnv, msntLoginUrl, articleId , articleUrl} = data;

  const coralTestArticles = [
    '456918',
    '450453',
    '450256',
    '363746',
    '511671',
    '652732',
    '420191',
    '661514',
    '688687',
    '711568',
    '580580',
    '673267',
    '502780',
    '481453',
    '518601',
    '588473',
    '721856',
    '721858',
    '721860'
  ];

  // hack to prevent coral comments on stable env except specific article
    // required because we share same Coral instance and loading article on
    // stable may break loading of comments on production
    if (currentEnv === 'stable' &&  !coralTestArticles.includes(articleId)) {
      commentsContainer.remove();
      return;
    }

    try {
      const response = await fetch(`${window.location.origin}/coral-jwt/`).then(data => data.json());
      accessToken = response.data?.token;
    } catch (error) {
      console.error(error);
    }
    //console.log({'baseUrl': baseUrl, 'embedPath': embedPath, 'articleId': articleId, 'articleUrl': articleUrl});
      var d = document, s = d.createElement('script');
      s.src = `${baseUrl}${embedPath}`;
      s.async = false;
      s.defer = true;
      s.onload = function() {
        const api = Coral.createStreamEmbed({
              id: "coral_thread",
              autoRender: true,
              rootURL: baseUrl,
              storyID: articleId,
              storyURL: articleUrl,
              containerClassName: 'coral-container-' + colorSheme,
              accessToken,
              events: function (events) {
                  events.on("loginPrompt", function () {
                      if ('msnt' in window) {
                        msnt.urls.login = msntLoginUrl;
                        msnt.openLoginWindow();
                      }
                  });
              },
          });

          import('./comments-ads-virtual').then((module) => {
            module.default(api, data);

            api.render();
          });
      };
      (d.head || d.body).appendChild(s);

}

export function setCookie(name, value, expireDate) {
  if (!expireDate) {
    document.cookie = `${name}=${value}; path=/`;
    return;
  }

  let date = new Date();
  date.setTime(date.getTime() + (expireDate * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

export function removeCookie(name) {
  document.cookie = `${name}=; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
}

export function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export async function getJson(url) {
  try {
    let res = await fetch(url);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
}

export function checkTcfApi() {
  return new Promise((resolve, reject) => {
      if (typeof __tcfapi === "function") {
        console.log('work!');
          resolve();
          return;
      }

      let timeoutId;

      const check = () => {
        console.log('work!2');
          if (typeof __tcfapi === "function") {
              clearTimeout(timeoutId);
              resolve();
          } else {
              timeoutId = setTimeout(check, 100);
          }
      };

      check();
  });
}
